/**
 * SEO component that queries for data with
 * Gatsby's `useStaticQuery` React hook.
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  title,
  description = '',
  image = undefined,
  url = undefined,
  lang = 'en',
  locale = 'en_US',
  type = 'article',
  meta = [],
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            description
          }
        }
      }
    `,
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.siteName}`}
      meta={[
        {
          name: 'description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:type',
          content: type,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:url',
          content: url,
        },
      ].concat(meta)}
    />
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
  locale: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
