import { useEffect, useState } from 'react';
import createPersistedState from 'use-persisted-state';

/**
 * Limit the number of calls to `callback` by only allowing 1 call within the `interval` time
 * period. For example, useful in scroll listeners to limit execution of a heavy computation.
 * @param {() => void} callback
 * @param {number} interval
 */
export function throttle(callback, interval) {
  let isReady = true;
  return function (...args) {
    if (!isReady) return;
    isReady = false;
    callback.apply(this, args);
    setTimeout(() => (isReady = true), interval);
  };
}

/**
 * Limit the number of calls to `callback` by waiting for `interval` length of silence.
 * For example, useful in autocomplete feature to wait until user has stopped typing before
 * hitting the backend API.
 * @param {() => void} callback
 * @param {number} interval
 */
export function debounce(callback, interval) {
  let timerId;
  return function (...args) {
    clearTimeout(timerId);
    timerId = setTimeout(() => callback.apply(this, args), interval);
  };
}

/**
 * Shallow equality of array, by comparing each element.
 * @param {any[]} a
 * @param {any[]} b
 */
export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

/**
 * Given an array of DOM element IDs that demarcate the start of content sections, return those for
 * sections that are currently visible in the viewport. Note: A `topPx`/`topPercent` greater than 0
 * is recommended since jumping to an anchor link often positions item at vertical position greater
 * than 0, so would erraneously list the previous section as also active.
 * @param {string[]} allIds
 *     DOM element IDs, in the order that the elements appear vertically on the page.
 * @param {{}} settings
 *     topPx/bottomPx: Offset from top/bottom of viewport to trigger inactive/active thresholds.
 *     topFrac/bottomFrac: Percent of viewport height to offset from top/bottom.
 *     The larger value of `[*]Px` and `[*]Percent` takes precedence.
 *     throttleMs: Number of milliseconds to throttle scroll handling; default 0;
 */
export function useActiveSections(
  allIds,
  {
    topPx = 10,
    topPercent = 0,
    bottomPx = 0,
    bottomPercent = 0,
    throttleMs = 50,
    debounceMs = 0,
  } = {},
) {
  const [activeIds, setActiveIds] = useState([]);

  const traverseSections = () => {
    let height = window.innerHeight;
    const ids = [];
    for (let i = allIds.length - 1; i >= 0; i--) {
      // Working backwards from bottom-most element.
      const target = document.getElementById(allIds[i]);
      if (!target) {
        console.warn(`Could not find element with id: ${allIds[i]}`);
        continue;
      }
      const bounds = target.getBoundingClientRect();
      const thresholdTop = Math.max(topPx, (topPercent / 100) * height);
      const thresholdBottom = height - Math.max(bottomPx, (bottomPercent / 100) * height);
      if (thresholdTop <= bounds.top && bounds.top < thresholdBottom) {
        // Intersects with window --> active.
        ids.push(allIds[i]);
      } else if (bounds.top < thresholdTop) {
        // First heading just above window --> active.
        ids.push(allIds[i]);
        break;
      } else if (i === 0) {
        // All headings below window --> top-most is active.
        ids.push(allIds[i]);
      }
    }
    ids.reverse(); // List from top-most element.
    if (!arraysEqual(activeIds, ids)) setActiveIds(ids); // Only register changes.
  };

  let listener = traverseSections;
  if (throttleMs) listener = throttle(listener, throttleMs);
  if (debounceMs) listener = debounce(listener, debounceMs);

  // Trigger active section calculation on mount (once) and on scroll events (throttled).
  useEffect(() => traverseSections(), []);
  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return activeIds;
}

/**
 * Do not update the `value` until the `interval` has elapsed.
 * @param {any} value
 * @param {number} interval
 */
export function useDebounce(value, interval) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    // Update debounced value after interval.
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, interval);

    // Cancel the timeout if value changes (also on interval change or unmount).
    return () => {
      clearTimeout(handler);
    };
  }, [value, interval]);
  return debouncedValue;
}

/**
 * Allows using media queries in component logic. For example, you could render a different number
 * of columns depending on which media query matches the current screen width (using queries =
 * ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'] and value = [5, 4, 3]).
 * @param {string[]} queries
 * @param {any[]} values
 * @param {any} defaultValue
 */
export function useMedia(queries, values, defaultValue) {
  const mediaQueryLists =
    typeof window === 'undefined' ? [] : queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query.
  const getValue = () => {
    // Get index of first media query that matches.
    const index = mediaQueryLists.findIndex((mql) => mql.matches);

    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  // Attach listeners that execute when the media queries change.
  useEffect(() => {
    // Event listener callback. By defining getValue outside of useEffect we ensure that it has
    // current values of hook args (as this hook callback is created once on mount).
    const handler = () => setValue(getValue);

    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, []);

  return value;
}

// Persists to local storeage and syncs across tabs/windows.
const useDarkModeState = createPersistedState('dark-mode');

/**
 * Stores dark mode state to persist between sessions and sync across tabs/windows.
 */
export function useDarkMode() {
  const [isDarkMode, setIsDarkMode] = useDarkModeState(false);
  return [isDarkMode, setIsDarkMode];
}
