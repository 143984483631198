import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';

import { useDarkMode } from '../utils/hooks';
import { rhythm, themeLight, themeDark } from '../utils/typography';
import { prismLight, prismDark } from '../utils/prism';

// Grid constants.
export const kGridSidebar = 190;
export const kGridBody = 625;
export const kGridMargin = 225;
export const kGridGap = 20;

// Adopt a desktop-first approach. Default styles apply to 3-column desktop. The breakpoints
// progressibly refine the size, making the layout simpler and simpler.

// Showing 2 columns.
export const kBreakpoint2 = `@media (max-width: ${
  kGridSidebar + kGridBody + kGridMargin + kGridGap * 4 - 1
}px)`;

// Showing 1 column.
export const kBreakpoint1 = `@media (max-width: ${kGridBody + kGridMargin + kGridGap * 3 - 1}px)`;

// Not showing 1 column.
export const kBreakpointNot1 = `@media (min-width: ${kGridBody + kGridMargin + kGridGap * 3}px)`;

export const kGridStyles = {
  display: 'grid',
  gridTemplateColumns: `[screen-start] minmax(0, 1fr) [page-start sidebar-start] ${kGridSidebar}px [sidebar-end body-start] ${kGridBody}px [body-end margin-start] ${kGridMargin}px [margin-end page-end] minmax(0, 1fr) [screen-end]`,
  gridTemplateRows: 'auto',
  gridColumnGap: kGridGap,
  [kBreakpoint2]: {
    gridTemplateColumns: `[screen-start] minmax(0, 1fr) [page-start sidebar-start body-start] ${kGridBody}px [body-end margin-start] ${kGridMargin}px [margin-end sidebar-end page-end] minmax(0, 1fr) [screen-end]`,
  },
  [kBreakpoint1]: {
    gridTemplateColumns: `[screen-start] minmax(0, 1fr) [page-start sidebar-start margin-start body-start] minmax(0, ${kGridBody}px) [body-end margin-end sidebar-end page-end] minmax(0, 1fr) [screen-end]`,
  },
};

const Header = styled.header({
  ...kGridStyles,
});

const HeaderContent = styled.div(({ theme }) => ({
  gridColumn: 'page-start / page-end',
  paddingTop: rhythm(1 / 4),
  paddingBottom: rhythm(1 / 4),
  display: 'flex',
  justifyContent: 'space-between',
}));

const Subtitle = styled.div(({ theme }) => ({
  color: theme.gray.light,
  fontWeight: 500,
}));

const Buttons = styled.div(({ theme }) => ({
  '& i': {
    paddingLeft: 2,
    color: theme.gray.lighter,
  },
  '& i:hover': {
    color: theme.gray.base,
  },
}));

const Main = styled.main({
  ...kGridStyles,
});

const Footer = styled.footer({
  ...kGridStyles,
});

const FooterContent = styled.div(({ theme }) => ({
  gridColumn: 'page-start / page-end',
  paddingTop: rhythm(1 / 4),
  paddingBottom: rhythm(1 / 4),
  fontSize: '0.75rem',
  color: theme.gray.light,
}));

export default function Layout({ location, siteName, children }) {
  const [isDarkMode, setIsDarkMode] = useDarkMode();
  const theme = isDarkMode ? themeDark : themeLight;

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={{
          html: {
            overflowX: 'hidden',
            backgroundColor: theme.background,
          },
          body: {
            color: theme.gray.darkest,
          },
          a: {
            color: theme.gray.darkest,
            textDecoration: 'none',
            borderBottom: `${theme.gray.lighter} 2px solid`,
            transition: 'border-bottom 0.2s ease',
            '&:hover, &:active': {
              borderBottom: `${theme.gray.dark} 2px solid`,
            },
          },
        }}
      />
      <Global
        styles={css`
          ${isDarkMode ? prismDark : prismLight}
        `}
      />
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        css={{ display: 'none' }}
        colorInterpolationFilters='sRGB'
      >
        <defs>
          <filter id='darkmode'>
            <feComponentTransfer>
              <feFuncR type='gamma' amplitude='10' exponent='8' offset='0.75'></feFuncR>
              <feFuncG type='gamma' amplitude='10' exponent='8' offset='0.75'></feFuncG>
              <feFuncB type='gamma' amplitude='10' exponent='8' offset='0.75'></feFuncB>
            </feComponentTransfer>
            <feColorMatrix type='saturate' values='4' />
          </filter>
        </defs>
      </svg>
      <Header>
        <HeaderContent>
          <Link
            style={{
              borderBottom: 'none',
              color: 'inherit',
              fontWeight: 'bold',
            }}
            to={`/`}
          >
            {siteName}
          </Link>
          <Buttons>
            {isDarkMode ? (
              <i className='fas fa-sun' onClick={() => setIsDarkMode(false)}></i>
            ) : (
              <i className='fas fa-moon' onClick={() => setIsDarkMode(true)}></i>
            )}
          </Buttons>
        </HeaderContent>
      </Header>
      <Main>{children}</Main>
      <Footer>
        <FooterContent>
          © {new Date().getFullYear()} {siteName}
        </FooterContent>
      </Footer>
    </ThemeProvider>
  );
}
