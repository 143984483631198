import Typography from 'typography';
// import filters from '../assets/filters.svg';

// export const filterDark = 'invert(100%) hue-rotate(180deg) brightness(180%) saturate(140%) contrast(120%)';
// export const filterDark = 'brightness(125%) saturate(125%)';
// export const filterDark = `url(${filters}#darkmode)`;
// export const filterDark = {
//   filter: `url(${filters}#darkmode)`,
// };
// export const filterDark = `url(#darkmode)`;
export const filterDark = 'invert(42%) contrast(160%) brightness(170%) saturate(500%)';

export const palette = {
  blue: 'hsl(211, 36%, 48%)',
  teal: 'hsl(175, 31%, 59%)',
  green: 'hsl(113, 34%, 47%)',
  yellow: 'hsl(47, 100%, 47%)',
  orange: 'hsl(30, 88%, 56%)',
  red: 'hsl(359, 70%, 61%)',
  pink: 'hsl(354, 100%, 81%)',
  purple: 'hsl(317, 25%, 58%)',
  brown: 'hsl(22, 24%, 49%)',
};

export const themeLight = {
  dark: false,
  background: 'hsl(40, 40%, 99%)', // Page background.
  foreground: 'hsl(30, 40%, 96%)', // Fill of elements standing out from background.
  gray: {
    lightest: 'hsl(40, 10%, 95%)', // Container tints, secondary buttons.
    lighter: 'hsl(40, 10%, 80%)', // Container borders, badges, chips.
    light: 'hsl(40, 10%, 65%)', // Disabled text.
    base: 'hsl(40, 10%, 50%)', // Icons, primary buttons.
    dark: 'hsl(40, 10%, 35%)', // Caption text, label text.
    darker: 'hsl(40, 10%, 20%)', // Body text.
    darkest: 'hsl(40, 10%, 5%)', // Heading text.
  },
  primary: {
    light: 'hsl(180, 70%, 85%)',
    base: 'hsl(180, 70%, 40%)',
    dark: 'hsl(180, 70%, 30%)',
  },
  ...palette,
};

export const themeDark = {
  dark: true,
  background: 'hsl(255, 10%, 8%)', // Page background.
  foreground: 'hsl(245, 10%, 14%)', // Fill of elements standing out from background.
  gray: {
    lightest: 'hsl(255, 11%, 13%)', // Container tints, secondary buttons.
    lighter: 'hsl(255, 10%, 26%)', // Container borders, badges, chips.
    light: 'hsl(255, 9%, 39%)', // Disabled text.
    base: 'hsl(255, 8%, 52%)', // Icons, primary buttons.
    dark: 'hsl(255, 7%, 65%)', // Caption text, label text.
    darker: 'hsl(255, 6%, 78%)', // Body text.
    darkest: 'hsl(255, 5%, 91%)', // Heading text.
  },
  primary: {
    light: 'hsl(180, 60%, 35%)',
    base: 'hsl(180, 60%, 50%)',
    dark: 'hsl(180, 60%, 65%)',
  },
  ...palette,
};

const typography = new Typography({
  title: 'Minimalist',
  scaleRatio: 1.5,
  headerFontFamily: ['Helvetica Neue', 'sans-serif'],
  bodyFontFamily: ['Helvetica Neue', 'sans-serif'],
  blockMarginBottom: 0.5,
  overrideStyles: () => ({
    'td,th': {
      textAlign: null, // Enable cell-specific alignment.
    },
  }),
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

export default typography;
export const { rhythm, scale, options } = typography;
